<template>
    <div class="allCourse">
        <nav-view :titleName="titleName"></nav-view>
        <!-- 分类 Start -->
        <div style="width:100%;height:235px;background:#fff;">
            <div class="classify flex justbetween alignCenter centerWidth marginAuto" v-for="(im,ix) in typeList" :key="ix" >
                <div class="classifyLeft">{{im.title}}</div>
                <div class="classifyRight">
                    <span class="classifyCurrns cursorP hovers" v-for="(jm,jx) in im.children" :key="jx" :class="classifyCurr[ix] == jm.id ? 'awaysBackground' : ''" @click="typeTab(im,jx)">
                        {{jm.name}}
                    </span>
                </div>
            </div>
        </div>
        <div class="centerWidth marginAuto" style="min-height:300px;margin-top:50px;">
            <course-list v-if="courseLists[showIndex] && courseLists[showIndex].length > 0" :courseList="courseLists[showIndex]" :showType="1" :showNumber="4" ></course-list>
            <temp-data v-else></temp-data>
        </div>
        <el-pagination
        style="margin-top:37px;"
        background
        layout="prev, pager, next"
        :total="dataCount"
        :page-size="12"
        @current-change="currentChange">
        </el-pagination>
        <!-- 分类 End -->
    </div>
</template>
<script>
import { course_type, course_by_type } from '@/utils/Api/homeList'
import navView from '../../../components/navView.vue'
import tempData from '@/components/tempData'
import courseList from '@/components/courseList'
export default {
    name:'allCourse',
    components:{navView,courseList, tempData},
    data(){
        return{
            titleName: '全部课程',
            classifyCurr: [0,0,0],
            typeList:[
                {
                    title:'一级分类：',
                    level: 1,
                    children:[]
                },
                {
                    title:'二级分类：',
                    level: 2,
                    children:[]
                },
                {
                    title:'三级分类：',
                    level: 3,
                    children:[]
                }
            ],
            page: 1,
            dataCount: 0,
            level:1,
            type_id:0,
            tabSave:{
                level:{
                    level:0,
                    type_id:0
                },
                level2:{
                    level:0,
                    type_id:0
                },
                level3:{
                    level:0,
                    type_id:0
                }
            },
            courseLists:[],
            showIndex:0,
            routeData:{}
        }
    },
    created(){
        this.initial()
        this.routeData = this.$route.query
    },
    watch:{
        courseLists(){
        },
        page(){
            this.showIndex = this.page - 1
        }
    },
    methods:{
        async initial(){
            /* 一级分类 */
            let typedata = await course_type({level:1})
            if(typedata){
                this.classifyCurr[0] = typedata[0].id
                if(this.routeData.id){this.classifyCurr[0] = this.routeData.id;this.routeData.id=null}
                this.assign(0,typedata)
                this.level2()
            }else{
                this.$message.error('一级分类获取失败')
            }
        },
        async level2(){
            let typedata = await course_type({level:2, type_id: this.classifyCurr[0]})
            if(typedata){
                this.level = 2
                this.assign(1,typedata)
                if(typedata.length < 1){
                    this.assign(2,typedata)
                }
                this.type_id = typedata[0].id
                this.classifyCurr[1] = typedata[0].id
                if(this.routeData.pid){this.type_id = this.classifyCurr[1] = this.routeData.pid;this.routeData.pid=null}
                this.level3()
            }else{
                this.$message.error('二级分类获取失败')
            }
        },
        async level3(){
            let typedata = await course_type({level:3, type_id: this.classifyCurr[1]})
            if(typedata){
                this.courseLists = []
                this.assign(2,typedata,3)
                this.classifyCurr[2] = typedata[0].id
                this.courseByType()
            }else{
                this.$message.error('三级分类获取失败')
            }
        },
        /* 赋值 */
        assign(index, data, level){
            this.typeList[index].children = data
            if(level){
                this.typeList[index].children.unshift({
                    icon: "",
                    id: 999999999999999,
                    name: "全部"
                })
            }
        },
        typeTab(value,index){
            if(value.level == 1){
                this.courseLists = []
                this.classifyCurr[0] = value.children[index].id
                this.level2()
                return
            }
            if(value.level == 2){
                this.courseLists = []
                this.classifyCurr[1] = value.children[index].id
                this.level3()
                this.tabSave.level2.level = value.level
                this.tabSave.level2.type_id = value.children[index].id
                this.level = value.level
                this.type_id = value.children[index].id
                return
            }
            if(value.level == 3){this.courseLists = [];this.classifyCurr[2] = value.children[index].id}
            if(value.level == 3 && value.children[index].name == '全部'){
                this.level = this.tabSave.level2.level
                this.type_id = this.tabSave.level2.type_id
            }else{
                this.level = value.level
                this.type_id = value.children[index].id
            }
            this.courseByType()
        },
        /* 查询课程 */
        async courseByType(){
            let params = {
                level: this.level,
                type_id: this.type_id,
                page: this.page,
                limit: 12
            }
            if(this.courseLists[this.page - 1]){
                return
            }
            let tempCourse = await course_by_type(params)
            if(tempCourse){
                this.courseLists[this.page - 1] = tempCourse.course
            }
            this.dataCount = tempCourse.count
            this.$forceUpdate()
        },
        /* 改变页数 */
        currentChange(value){
            this.page = value
            this.courseByType()
            this.scrollTop()
        }
    }
}
</script>
<style lang="scss" scoped>
.allCourse{
    background: #F7F8FA;
    .classify{
        padding-top: 26px;
        background: #fff;
        .classifyLeft{
            width: 89px;
        }
        .classifyRight{
            flex: 1;
            text-align: left;
        }
        .classifyCurrns{
            display: inline-block;
            font-size: 14px;
            line-height: 36px;
            padding-left: 15px;
            padding-right: 15px;
            border-radius: 50px;
            margin-right: 23px;
        }
    }
}
</style>